import React, { useState, useMemo, useContext } from "react";
import "./skipdash.css";
import profileImg from "../assets/bree.jpg"; // Replace with your local image
import Logo from "../assets/logo.png";
import { Input } from "antd";
import { BellFilled } from "@ant-design/icons";
import { WechatOutlined } from "@ant-design/icons";
import { AppstoreFilled } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Button, Popover, Avatar } from "antd";
import Notifications from "./Notifications";
import { AuthContext } from "../logic/AuthContext";

function DashHeader({ user }) {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState("Show");
  const [open3, setOpen3] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);


  const ImageURl = user?.userImage;

  const content_ = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <button onClick={logout} className="logout-btn">
        Logout
      </button>
    </div>
  );

  const content_events = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p onClick={logout} className="frnds-chat">
        Event Details and ticket
      </p>
    </div>
  );
  const content_friends = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p onClick={logout} className="frnds-chat">
        Friends List & Chat List
      </p>
    </div>
  );
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOpenChange2 = (newOpen) => {
    setOpen2(newOpen);
  };
  const handleOpenChange1 = (newOpen) => {
    setOpen1(newOpen);
  };


  
  const handleOpenChange3 = (newOpen) => {
    setOpen3(newOpen);
  };

  const handleOpenChange5 = (newOpen) => {
    setOpen5(newOpen);
  };
  const handleOpenChange6 = (newOpen) => {
    setOpen6(newOpen);
  };

  //LOGOUT FUNCTION
  const logout_ = (newOpen) => {
    setOpen4(newOpen);
    navigate("/")
  };
  return (
    <div className="header2">
      <div className="header-left">
        <Link to="/">
          <img src={Logo} alt="skip Logo" className="logo" />
        </Link>
        <Input type="text" className="search_input" placeholder="Search Skip" />
      </div>
      <div className="header-mobile">
        <div className="header_ics2">
          <Popover
            content={content_events}
            title="Friends and Chat"
            trigger="click"
            placement="bottom"
            arrow={mergedArrow}
            open={open3}
            onOpenChange={handleOpenChange3}
          >
            <AppstoreFilled className="notification-bell shadeMe" />
          </Popover>{" "}
          <Popover
            content={content_friends}
            title="Chat"
            trigger="click"
            arrow={mergedArrow}
            placement="bottom"
            open={open5}
            onOpenChange={handleOpenChange5}
          >
            <WechatOutlined className="notification-bell shadeMe" />
          </Popover>
          <Popover
            content={<Notifications />}
            title="Skip Notifications"
            trigger="click"
            arrow={mergedArrow}
            placement="bottom"
            open={open6}
            onOpenChange={handleOpenChange6}
          >
            <BellFilled className="notification-bell shadeMe" />
          </Popover>
        </div>
      </div>
      <div className="header-right space-me">
        {/* <div className="main_header">
          <div className="header_ics">
            <Popover
              content={<Notifications />}
              title="Menu"
              trigger="click"
              placement="left"
              arrow={mergedArrow}
              open={open1}
              onOpenChange={handleOpenChange1}
            >
              <AppstoreFilled className="notification-bell shadeMe" />
            </Popover>{" "}
            <Popover
              content={<Notifications />}
              title="Chat"
              trigger="click"
              arrow={mergedArrow}
              placement="left"
              open={open2}
              onOpenChange={handleOpenChange2}
            >
              <WechatOutlined className="notification-bell shadeMe" />
            </Popover>
            <Popover
              content={<Notifications />}
              title="Skip Notifications"
              trigger="click"
              arrow={mergedArrow}
              placement="left"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <BellFilled className="notification-bell shadeMe" />
            </Popover>
          </div>
        </div> */}

        <Popover
          content={content_}
          trigger="click"
          arrow={mergedArrow}
          placement="bottom"
          open={open4}
          onOpenChange={logout_}
        >

{/* <img src={ImageURl} alt="profile image" /> */}
<Avatar className="logged-in-user" src={user?.userImage} size="large" alt="User Image" />
        </Popover>
      </div>
    </div>
  );
}

export default DashHeader;
