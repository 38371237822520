import React from 'react'

function EventsAttended() {
  return (
    <div>

        coming soon
    </div>
  )
}

export default EventsAttended